import styled from "@emotion/styled";

import * as Text from "@/components/atoms/common/Text";

const ErrorPageContainer = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;

  width: 100%;

  margin-top: 6.25rem;
`;

const ErrorPageText = styled(Text.Nanum400)`
  font-size: 1.3125rem;
  line-height: 2rem;

  color: ${({ theme }) => theme.colors.black70};
`;

const ErrorPage = () => {
  return (
    <ErrorPageContainer>
      <ErrorPageText>
        네트워크 이슈로 공시를 불러오지 못했습니다.
        <br />
        잠시 후 다시 시도해주세요.
      </ErrorPageText>
    </ErrorPageContainer>
  );
};

export default ErrorPage;
