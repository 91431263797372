import { EXCHANGE } from "@/types/EXCHANGE";
import { T_RESPONSE_SHENZHEN_FILINGS } from "@/types/model/response/ShenzhenFilingType";
import { MODEL_VIEW_FILINGS_ITEM } from "@/types/model/view/ParsedFilingType";
import { getKoreanValue } from "@/utils/parser/parseFilingResponse";

type T_PARSE_SHENZHEN_FILING = (
  data: T_RESPONSE_SHENZHEN_FILINGS
) => MODEL_VIEW_FILINGS_ITEM[];

const parseShenzhenFiling: T_PARSE_SHENZHEN_FILING = (data) => {
  if (!data.getDisclosure) return [];
  return data.getDisclosure.map((item) => {
    return {
      id: item.id,
      dataDate: item.dataDate,
      form: getKoreanValue(item.details.categoryId[0]),
      topic: item.analysisDetails.topicKor,
      summaryTiny: item.analysisDetails.summarizeTinyKor,
      summaryLong: item.analysisDetails.summarizeLongKor,
      originURL: `https://www.szse.cn/api/disc/info/download?id=${item.id}`,
      fileType: item.details.fileType,
      exchange: EXCHANGE.SHENZHEN,
      secCode: item.details.secCode[0]
    };
  });
};

export default parseShenzhenFiling;
