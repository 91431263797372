import styled from "@emotion/styled";

import FilingItemBody from "@/components/molecules/list/FilingItemBody";
import FilingItemFooter from "@/components/molecules/list/FilingItemFooter";
import FilingItemHeader from "@/components/molecules/list/FilingItemHeader";
import { MODEL_VIEW_FILINGS_ITEM } from "@/types/model/view/ParsedFilingType";

interface FilingItemProps {
  item: MODEL_VIEW_FILINGS_ITEM;
}

const FilingItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  padding-bottom: 1rem;
  margin-top: 1.25rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.white30};

  &:last-child {
    border-bottom: none;
  }
`;

const FilingItem = ({ item }: FilingItemProps) => {
  return (
    <FilingItemContainer>
      <FilingItemHeader item={item} />
      <FilingItemBody item={item} />
      <FilingItemFooter item={item} />
    </FilingItemContainer>
  );
};

export default FilingItem;
