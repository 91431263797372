import { BrowserRouter, Routes, Route } from "react-router-dom";

import MainPage from "@/components/pages/MainPage";
import DetailPage from "@/components/pages/DetailPage";
import RouteGuard from "@/components/pages/RouteGuard";
import MembersPage from "@/components/pages/MembersPage";
import MembersDetailPage from "@/components/pages/MembersDetailPage";

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<RouteGuard />} />
        <Route path="/list" element={<MainPage />} />
        <Route path="/detail" element={<DetailPage />} />
        <Route path="/members" element={<MembersPage />} />
        <Route path="/members/detail" element={<MembersDetailPage />} />
        <Route path="*" element={<h1>Not Found</h1>} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
