import styled from "@emotion/styled";

import TimeStamp from "@/components/atoms/common/TimeStamp";

import { MODEL_VIEW_FILINGS_ITEM } from "@/types/model/view/ParsedFilingType";
import generateLocaleTime from "@/utils/generateLocaleTime";

interface FilingItemFooterProps {
  item: MODEL_VIEW_FILINGS_ITEM;
}

const FilingItemFooterContainer = styled.div`
  display: flex;
  flex-direction: row;

  gap: 0.875rem;

  width: 100%;
`;

const FilingItemFooter = ({ item }: FilingItemFooterProps) => {
  const { koreaDay, koreaHour } = generateLocaleTime(item.dataDate);

  return (
    <FilingItemFooterContainer>
      <TimeStamp time={koreaDay} />
      <TimeStamp time={koreaHour} />
    </FilingItemFooterContainer>
  );
};

export default FilingItemFooter;
