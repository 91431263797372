import Loader from "@/components/organisms/common/Loading";
import { Suspense, lazy } from "react";

const DetailPage = () => {
  const Detail = lazy(() => import("@/components/templates/detail"));

  return (
    <Suspense fallback={<Loader />}>
      <Detail />
    </Suspense>
  );
};

export default DetailPage;
