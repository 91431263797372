import { EXCHANGE } from "@/types/EXCHANGE";
import { T_RESPONSE_GERMANY_FILINGS } from "@/types/model/response/GermanyFilingType";
import { MODEL_VIEW_FILINGS_ITEM } from "@/types/model/view/ParsedFilingType";

type T_PARSE_GERMANY_FILING = (
  data: T_RESPONSE_GERMANY_FILINGS
) => MODEL_VIEW_FILINGS_ITEM[];

const parseGermanyFiling: T_PARSE_GERMANY_FILING = (data) => {
  if (!data.getDisclosure) return [];
  return data.getDisclosure.map((item) => {
    return {
      id: item.id,
      dataDate: item.dataDate,
      form: "",
      topic: item.analysisDetails.topicKor ?? "",
      summaryTiny: item.analysisDetails.summarizeTinyKor ?? "",
      summaryLong: item.analysisDetails.summarizeLongKor ?? "",
      originURL: item.details.detailUrl ?? "",
      fileType: (item.details.detailUrl ?? "").split(".").pop() || "",
      exchange: EXCHANGE.GERMANY,
      secCode: item.details.secCode ?? ""
    };
  });
};

export default parseGermanyFiling;
