import { EXCHANGE } from "@/types/EXCHANGE";
import { T_RESPONSE_HONGKONG_FILINGS } from "@/types/model/response/HongkongFilingType";
import { MODEL_VIEW_FILINGS_ITEM } from "@/types/model/view/ParsedFilingType";
import { getKoreanValue } from "@/utils/parser/parseFilingResponse";

type T_PARSE_HONGKONG_FILING = (
  data: T_RESPONSE_HONGKONG_FILINGS
) => MODEL_VIEW_FILINGS_ITEM[];

const parseHongkongFiling: T_PARSE_HONGKONG_FILING = (data) => {
  if (!data.getDisclosure) return [];
  return data.getDisclosure.map((item) => {
    return {
      id: item.id,
      dataDate: item.dataDate,
      form: getKoreanValue(item.details.categoryId),
      topic: item.analysisDetails.topicKor,
      summaryTiny: item.analysisDetails.summarizeTinyKor,
      summaryLong: item.analysisDetails.summarizeLongKor,
      originURL: item.details.fileLink,
      fileType: item.details.fileType,
      exchange: EXCHANGE.HONGKONG,
      secCode: item.details.secCode[0]
    };
  });
};

export default parseHongkongFiling;
