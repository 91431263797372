import { EXCHANGE } from "@/types/EXCHANGE";
import { T_RESPONSE_USA_FILINGS } from "@/types/model/response/USAFilingType";
import { MODEL_VIEW_FILINGS_ITEM } from "@/types/model/view/ParsedFilingType";
import {
  getKoreanEventLabel,
  getKoreanValue
} from "@/utils/parser/parseFilingResponse";
import parseFormType10, {
  ParsedForm10Data
} from "@/utils/parser/parseFormType10";
import parseFormType14 from "@/utils/parser/parseFormType14";

const USA_AMENDMENT = [
  "3/A",
  "4/A",
  "5/A",
  "6-K/A",
  "8-K/A",
  "10-K/A",
  "10-Q/A",
  "20-F/A",
  "40-F/A",
  "424B3/A",
  "SC 13D/A",
  "SC 13G/A"
];

type T_PARSE_USA_FILING = (
  data: T_RESPONSE_USA_FILINGS
) => MODEL_VIEW_FILINGS_ITEM[];

const parseFormName = (form: string) => {
  if (form === "3" || form === "4" || form === "5") return `Form ${form}`;
  return form;
};

const sortByKeyAndReturnObjectArray = (obj: ParsedForm10Data) => {
  const keys = ["business", "risk", "legal", "repurchase", "finance"];
  const sortedObjectArray = keys
    .filter((key) => obj[key] !== undefined)
    .map((key) => ({
      key: key.charAt(0).toUpperCase() + key.slice(1),
      value: obj[key]
    }));
  return sortedObjectArray;
};

const parseUSAFiling: T_PARSE_USA_FILING = (data) => {
  if (!data.getDisclosure) return [];
  return data.getDisclosure.map((item) => {
    return {
      id: item.id,
      dataDate: item.dataDate,
      form: getKoreanValue(item.details.form),
      topic: `${parseFormName(item.details.form)}, ${
        item.analysisDetails.topicKor
      }`,
      summaryTiny: USA_AMENDMENT.includes(item.details.form)
        ? `(정정공시) ${item.analysisDetails.summarizeTinyKor}`
        : item.analysisDetails.summarizeTinyKor,
      summaryLong: item.analysisDetails.summarizeLongKor,
      eventLabel: item.details.eventLabel
        ? getKoreanEventLabel(item.details.eventLabel)
        : [],
      originURL: item.details.mainUrl,
      fileType: item.details.mainUrl.split(".").pop() || "",
      exchange: EXCHANGE.USA,
      secCode:
        typeof item.details.ticker !== "string"
          ? item.details.ticker.join(", ")
          : item.details.ticker,
      analysisForm10s: item.details.analysisForm10s
        ? sortByKeyAndReturnObjectArray(
            parseFormType10(item.details.analysisForm10s)
          )
        : null,
      analysisDEF14A: item.details.analysisDEF14A
        ? parseFormType14(item.details.analysisDEF14A)
        : null
    };
  });
};

export default parseUSAFiling;
