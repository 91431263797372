import styled from "@emotion/styled";

import { FadeLoader } from "react-spinners";

const LoadingContainer = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
`;

const Loader = () => {
  return (
    <LoadingContainer>
      <FadeLoader color="#999999" />
    </LoadingContainer>
  );
};

export default Loader;
