import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const RouteGuard = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (pathname === "/") {
      navigate("/list?country_code=USA&exchange=NQQ&sec_code=TSLA");
    }
  }, [pathname]);

  return <div>RouteGuard</div>;
};

export default RouteGuard;
