import { MODEL_VIEW_FILINGS } from "@/utils/parser/parseFilingResponse";
import styled from "@emotion/styled";

import * as Text from "@/components/atoms/common/Text";
import MembersBody from "@/components/molecules/members/MembersBody";
import MembersFooter from "@/components/molecules/members/MembersFooter";
import MembersHeader from "@/components/molecules/members/MembersHeader";

const CardContainer = styled.div`
  width: 100%;
  overflow: hidden;

  padding: 0 1rem;
`;

const EmptyContainer = styled.div`
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
`;

const EmptyText = styled(Text.Nanum400)`
  font-size: 1.3125rem;
  line-height: 2rem;

  color: ${({ theme }) => theme.colors.black70};

  text-align: center;
`;

interface MembersCardProps {
  filing: MODEL_VIEW_FILINGS;
}

const MembersCard = ({ filing }: MembersCardProps) => {
  return (
    <CardContainer>
      {filing.length === 0 ? (
        <EmptyContainer>
          <EmptyText>조회할 공시가 없습니다.</EmptyText>
        </EmptyContainer>
      ) : (
        <>
          <MembersHeader formType={filing[0].form} />
          <MembersBody summaryLong={filing[0].summaryLong} />
          <MembersFooter dataDate={filing[0].dataDate} />
        </>
      )}
    </CardContainer>
  );
};

export default MembersCard;
