import Circle from "@/components/atoms/list/Circle";
import ToggleBack from "@/components/atoms/list/ToggleBack";
import useToggleStore from "@/stores/toggleStore";
import { logEvent } from "@/utils/logEvent";
import styled from "@emotion/styled";

const ToggleWrapper = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  width: 3rem;
  height: 1.75rem;

  transition: all 0.5s ease-in-out;
`;

const Toggle = () => {
  const isOn = useToggleStore((state) => state.isToggled);
  const changeToggle = useToggleStore((state) => state.toggle);

  const handleToggleClick = () => {
    changeToggle();
    logEvent("click toggle", {
      isToggled: !isOn
    });
  };

  return (
    <ToggleWrapper onClick={handleToggleClick}>
      <ToggleBack isOn={isOn} />
      <Circle isOn={isOn} />
    </ToggleWrapper>
  );
};

export default Toggle;
