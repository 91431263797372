import reportWebVitals from "@/reportWebVitals";
import { HttpClient as HttpClientIntegration } from "@sentry/integrations";
import { BrowserTracing, init as SentryInit } from "@sentry/react";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

import {
  Identify,
  identify,
  init as initAmplitude
} from "@amplitude/analytics-browser";

import "@/styles/font.css";
import "@/styles/globals.css";

if (import.meta.env.VITE_SENTRY_DSN_KEY) {
  const environment = import.meta.env.MODE || "unknown";
  const release =
    import.meta.env.VITE_PACKAGE_NAME && import.meta.env.VITE_PACKAGE_VERSION
      ? import.meta.env.VITE_PACKAGE_NAME +
        "@" +
        import.meta.env.VITE_PACKAGE_VERSION
      : "unknown@unknown";

  SentryInit({
    environment,
    release,
    dsn: import.meta.env.VITE_SENTRY_DSN_KEY,
    integrations: [
      new BrowserTracing(),
      new HttpClientIntegration({
        // This array can contain tuples of `[begin, end]` (both inclusive),
        // single status codes, or a combination of both.
        // default: [[500, 599]]
        failedRequestStatusCodes: [[400, 599]],

        // This array can contain Regexes, strings, or a combination of both.
        // default: [/.*/]
        failedRequestTargets: [import.meta.env.VITE_API_SERVER_URL]
      })
    ],

    tracesSampleRate: import.meta.env.MODE === "production" ? 0.1 : 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
  });
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

if (import.meta.env.VITE_AMPLITUDE_KEY) {
  initAmplitude(import.meta.env.VITE_AMPLITUDE_KEY);
  const event = new Identify();
  // track(import.meta.env.VITE_PACKAGE_NAME);
  identify(event);
}
