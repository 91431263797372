const parseSearchParams = (orgQueryStr: string): Record<string, string> => {
  const res: Record<string, string> = {};

  if (!orgQueryStr || orgQueryStr === "?") {
    return res;
  }

  const queryStr = orgQueryStr.replace("?", "");
  const queries = queryStr.split("&");

  if (!queries.length) {
    return res;
  }

  for (const query of queries) {
    const [field, value = ""] = query.split("=");
    res[field] = value;
  }

  return res;
};

export default parseSearchParams;
