export const CATEGORY_MAPPING_USA = {
  "3": { kor: "내부자 지분취득" },
  "3/A": { kor: "내부자 지분취득" },
  "4": { kor: "내부자 지분변동" },
  "4/A": { kor: "내부자 지분변동" },
  "5": { kor: "내부자 지분변동(연)" },
  "5/A": { kor: "내부자 지분변동(연)" },
  "6-K": { kor: "수시공시(외)" },
  "6-K/A": { kor: "수시공시(외)" },
  "8-K": { kor: "수시공시" },
  "8-K/A": { kor: "수시공시" },
  "10-K": { kor: "연간보고서" },
  "10-K/A": { kor: "연간보고서" },
  "10-Q": { kor: "분기보고서" },
  "10-Q/A": { kor: "분기보고서" },
  "20-F": { kor: "연간보고서(외)" },
  "20-F/A": { kor: "연간보고서(외" },
  "40-F": { kor: "연간보고서(캐)" },
  "40-F/A": { kor: "연간보고서(캐)" },
  "424B3": { kor: "증권공모" },
  "424B3/A": { kor: "증권공모" },
  "DEF 14A": { kor: "주주총회" },
  "SC 13D": { kor: "대주주 지분변동" },
  "SC 13D/A": { kor: "대주주 지분변동" },
  "SC 13G": { kor: "대주주 지분변동" },
  "SC 13G/A": { kor: "대주주 지분변동" }
};
// Amendment는 요약 앞에 (정정공시) 8-K/A, 6-K/A, DEFA14A, 5/A, 3/A, 8-A12B/A, 4/A

export const CATEGORY_MAPPING_SHENZHEN = {
  "010301": { chi: "年度报告", kor: "연간보고서" },
  "010303": { chi: "半年度报告", kor: "2분기보고서" },
  "010305": { chi: "一季度报告", kor: "1분기보고서" },
  "010307": { chi: "三季度报告", kor: "3분기보고서" },
  "0102": { chi: "首次公开发行及上市", kor: "기업공개/상장" },
  "0105": { chi: "配股", kor: "주식발행/증자" },
  "0107": { chi: "增发", kor: "증자/제3자배정" },
  "0109": { chi: "可转换债券", kor: "전환사채" },
  "0110": { chi: "权证相关公告", kor: "신주인수권부채권" },
  "0111": { chi: "其它融资", kor: "기타융자" },
  "0113": { chi: "权益分派与限制出售股份上市", kor: "배당/보호예수해제" },
  "0115": { chi: "股权变动", kor: "지분변경" },
  "0117": { chi: "交易", kor: "투자/거래" },
  "0119": { chi: "股东大会", kor: "주주총회" },
  "0121": { chi: "澄清、风险提示、业绩预告事项", kor: "정정/경고/실적전망" },
  "0125": { chi: "特别处理和退市", kor: "유의종목/상장폐지" },
  "0127": { chi: "补充及更正", kor: "정정공시" },
  "0129": { chi: "中介机构报告", kor: "외부감사보고" },
  "0131": { chi: "上市公司制度", kor: "경영주요공시" },
  "0139": { chi: "债券公告", kor: "채권발행" },
  "0123": { chi: "其它重大事项", kor: "기타주요사항" },
  "01239901": { chi: "董事会公告", kor: "이사회공시" },
  "01239910": { chi: "监事会公告", kor: "감사위원회공시" }
};

export const CATEGORY_MAPPING_SHANGHAI = {
  定期报告: { kor: "정기보고서" },
  董事会和监事会: { kor: "이사회/감사보고" },
  股东大会: { kor: "주주총회" },
  应当披露的交易: { kor: "공시의무거래" },
  首次公开发行: { kor: "기업공개" },
  关联交易: { kor: "특수관계자거래" },
  对外担保: { kor: "보증" },
  募集资金使用与管理: { kor: "재무" },
  "业绩预告、业绩快报和盈利预测": { kor: "실적" },
  利润分配和资本公积金转增股本: { kor: "배당/이익분배" },
  股票交易异常波动和澄清: { kor: "이상거래" },
  股份上市流通与股本变动: { kor: "보호예수해제" },
  股东增持或减持股份: { kor: "지분변동" },
  "权益变动报告书和（要约）收购": { kor: "지분/자본금 변동" },
  股权型再融资: { kor: "주식관련사채권" },
  其他再融资: { kor: "채권발행" },
  重大资产重组: { kor: "중대지분변경" },
  吸收合并: { kor: "흡수합병" },
  回购股份: { kor: "자사주매입" },
  可转换公司债: { kor: "전환사채" },
  股权激励及员工持股计划: { kor: "스톡옵션/우리사주" },
  诉讼和仲裁: { kor: "소송/중재" },
  股东股份被质押冻结或司法拍卖: { kor: "주식담보" },
  破产与重整: { kor: "파산/구조조정" },
  其他重大事项: { kor: "기타주요공시" },
  公司重要基本信息变化: { kor: "경영주요공시" },
  风险警示: { kor: "위험경고" },
  "暂停、恢复和终止上市": { kor: "상장폐지" },
  补充更正公告: { kor: "정정공시" },
  规范运作: { kor: "성과/주요사항" },
  中介机构报告: { kor: "외부감사보고" },
  停复牌提示性公告: { kor: "거래중단/재개" },
  优先股: { kor: "우선주발행/배당" },
  特别表决权: { kor: "특별의결권" },
  超额配售选择权: { kor: "초과배당" },
  存托凭证相关公告: { kor: "예탁증서" },
  询价转让及配售: { kor: "지분구조변경" },
  境内外同步披露: { kor: "해외공시정보" },
  其他: { kor: "기타경영정보" },
  年报: { kor: "연간보고서" },
  一季报: { kor: "1분기보고서" },
  三季报: { kor: "3분기보고서" },
  半年报: { kor: "2분기보고서" }
};

export const CATEGORY_MAPPING_HKEX = {
  "Announcements and Notices": { kor: "일반공시" },
  Circulars: { kor: "주요안내보고서" },
  "Constitutional Documents": { kor: "정관수정" },
  "Company Information Sheet": { kor: "회사개요" },
  "Debt and Structured Products": { kor: "부채/구조화상품" },
  "Documents on Display": { kor: "기타공시" },
  "Documents on Display (Debt Issuance Programmes)": { kor: "채권발행" },
  "Documents on Display (Debt Securities)": { kor: "채무증권" },
  "Documents on Display (Structured Products)": { kor: "구조화상품" },
  "Financial Statements/ESG Information": { kor: "재무/ESG" },
  "Listing Documents": { kor: "보고서" },
  "Monthly Returns": { kor: "월간보고서" },
  "Next Day Disclosure Returns": { kor: "발행주식수변동" },
  "Proxy Forms": { kor: "대리행사" },
  "Regulatory Announcement & News": { kor: "공지/뉴스" },
  "Takeovers Code - dealing disclosures": { kor: "거래공시" },
  "Trading Information of Exchange Traded Funds": { kor: "ETF정보" },
  "Trading Information of Leveraged and Inverse Products": {
    kor: "파생상품거래정보"
  }
};

export const CATEGORY_MAPPING_JAPAN = {
  "010": { jap: "有価証券通知書", kor: "유가증권통지서" },
  "020": { jap: "変更通知書（有価証券通知書）", kor: "유가증권통지서" },
  "030": { jap: "有価証券届出書", kor: "유가증권신고서" },
  "040": { jap: "訂正有価証券届出書", kor: "유가증권신고서" },
  "050": { jap: "届出の取下げ願い", kor: "신고취소" },
  "060": { jap: "発行登録通知書", kor: "발행등록통지서" },
  "070": { jap: "変更通知書（発行登録通知書）", kor: "발행등록통지서" },
  "080": { jap: "発行登録書", kor: "발행등록서" },
  "090": { jap: "訂正発行登録書", kor: "발행등록서" },
  "100": { jap: "発行登録追補書類", kor: "발행등록추가서류" },
  "110": { jap: "発行登録取下届出書", kor: "발행등록취하신고서" },
  "120": { jap: "有価証券報告書", kor: "유가증권보고서" },
  "130": { jap: "訂正有価証券報告書", kor: "유가증권보고서" },
  "135": { jap: "確認書", kor: "확인서" },
  "136": { jap: "訂正確認書", kor: "확인서" },
  "140": { jap: "四半期報告書", kor: "분기보고서" },
  "150": { jap: "訂正四半期報告書", kor: "분기보고서" },
  "160": { jap: "半期報告書", kor: "반기보고서" },
  "170": { jap: "訂正半期報告書", kor: "반기보고서" },
  "180": { jap: "臨時報告書", kor: "임시보고서" },
  "190": { jap: "訂正臨時報告書", kor: "임시보고서" },
  "200": { jap: "親会社等状況報告書", kor: "모회사상황보고서" },
  "210": { jap: "訂正親会社等状況報告書", kor: "모회사상황보고서" },
  "220": { jap: "自己株券買付状況報告書", kor: "자사주매입보고서" },
  "230": { jap: "訂正自己株券買付状況報告書", kor: "자사주매입보고서" },
  "235": { jap: "内部統制報告書", kor: "내부통제보고서" },
  "236": { jap: "訂正内部統制報告書", kor: "내부통제보고서" },
  "240": { jap: "公開買付届出書", kor: "공개매수신고서" },
  "250": { jap: "訂正公開買付届出書", kor: "공개매수신고서" },
  "260": { jap: "公開買付撤回届出書", kor: "공개매수철회신고서" },
  "270": { jap: "公開買付報告書", kor: "공개매수보고서" },
  "280": { jap: "訂正公開買付報告書", kor: "공개매수보고서" },
  "290": { jap: "意見表明報告書", kor: "의견표명보고서" },
  "300": { jap: "訂正意見表明報告書", kor: "의견표명보고서" },
  "310": { jap: "対質問回答報告書", kor: "질의응답보고서" },
  "320": { jap: "訂正対質問回答報告書", kor: "질의응답보고서" },
  "330": {
    jap: "別途買付け禁止の特例を受けるための申出書",
    kor: "별도매입특례신청서"
  },
  "340": {
    jap: "訂正別途買付け禁止の特例を受けるための申出書",
    kor: "별도매입특례신청서"
  },
  "350": { jap: "大量保有報告書", kor: "대량보유보고서" },
  "360": { jap: "訂正大量保有報告書", kor: "대량보유보고서" },
  "370": { jap: "基準日の届出書", kor: "기준일신고서" },
  "380": { jap: "変更の届出書", kor: "변경신고서" }
};

export const CATEGORY_MAPPING_HANOI = {
  CK_KHONGKYQUY: { eng: "List-ineligible for margin", kor: "마진거래불가종목" },
  SURVEILLAN: { eng: "Market Surveillance", kor: "시장감시결과" },
  DELIST: { eng: "Delisting/Move listing", kor: "상장폐지" },
  NEW_LIST: { eng: "New listing", kor: "신규상장" },
  ADD_LIST: { eng: "Additional listing", kor: "추가상장" },
  CH_RULE: { eng: "Change in Charter", kor: "내부규정개정" },
  TRADE_NEW: { eng: "Treasury Stock Trading", kor: "자사주거래" },
  TEND_OFFER: { eng: "Public Offering/Merger & Acquisition", kor: "IPO/M&A" },
  CORPORATE_NEWS: { eng: "Corporate action news", kor: "기업결정사항" },
  GEN_MEET: { eng: "General Meeting of shareholder", kor: "주주총회" },
  RESOLTION: { eng: "Board Resolution", kor: "이사회결의" },
  CH_LICENSE: { eng: "Change in Business Certificate", kor: "사업변경" },
  CH_MEMBER: { eng: "Change of personnel", kor: "주요인사변경" },
  FIN_REPORT: {
    eng: "Financial Statement, Explanation of Financial Statement",
    kor: "재무제표"
  },
  GOV_REPORT: { eng: "Corporate governance", kor: "기업지배구조보고서" },
  OTHER_NEW: { eng: "Others", kor: "기타" }
};

export const CATEGORY_MAPPING_LONDON = {
  SUS: { org: "Temporary Suspension", kor: "거래중단" },
  SRS: { org: "Statement re. Suspension", kor: "거래중단" },
  REN: { org: "Restoration of Listing", kor: "재상장" },
  NOT: { org: "Official List Notice", kor: "신규상장" },
  MSCU: { org: "Miscellaneous", kor: "중요공시" },
  QRF: { org: "1st Quarter Results", kor: "1분기보고서" },
  QRT: { org: "3rd Quarter Results", kor: "3분기보고서" },
  ACQ: { org: "Acquisition", kor: "인수합병" },
  AGM: { org: "AGM Statement", kor: "주주총회" },
  ACS: { org: "Annual Financial Report", kor: "재무재표" },
  CAR: { org: "Capital Reorganisation", kor: "자본재편" },
  CON: { org: "Conversion of Securities", kor: "전환증권" },
  TAB: { org: "Disclosure Table", kor: "주요공시" },
  DIS: { org: "Disposal", kor: "주요공시" },
  DRL: { org: "Drilling Report", kor: "주요공시" },
  EGM: { org: "EGM Statement", kor: "주주총회" },
  FR: { org: "Final Results", kor: "연간보고서" },
  FEE: {
    org: "Form 8 (OPD) [Insert name of offeree or offer-er]",
    kor: "주요공시"
  },
  FEO: { org: "Form 8.5 (EPT/NON-RI)", kor: "주요공시" },
  FER: { org: "Form 8.5 (EPT/RI)", kor: "주요공시" },
  FON: { org: "Formal Notice", kor: "주요공시" },
  FUR: { org: "Further re (insert  propriate text)", kor: "주요공시" },
  IR: { org: "Half-year Report", kor: "2분기보고서" },
  IMS: { org: "Interim Management  Statement", kor: "주요공시" },
  IOD: { org: "Issue of Debt", kor: "주요공시" },
  IOE: { org: "Issue ofEquity", kor: "주요공시" },
  LOI: { org: "Letter of Intent Signed", kor: "주요공시" },
  MER: { org: "Merger Update", kor: "인수합병" },
  OFB: { org: "Offer by  [add offeror’s name]", kor: "주요공시" },
  OFF: { org: "Offer for  [add offeree’s name]", kor: "주요공시" },
  OLA: { org: "Offer Lapsed", kor: "주요공시" },
  ORE: { org: "Offer Rejection", kor: "주요공시" },
  OTT: { org: "Offer Talks Terminated", kor: "주요공시" },
  OUP: { org: "Offer Update", kor: "주요공시" },
  PNM: { org: "Prior Notice of Merger", kor: "병합공시" },
  PRL: { org: "Product Launch", kor: "주요공시" },
  AGR: { org: "Re Agreement", kor: "주요공시" },
  SAL: { org: "Re Alliance", kor: "주요공시" },
  CNT: { org: "Re Contract", kor: "주요공시" },
  JVE: { org: "Re Joint Venture", kor: "주요공시" },
  RAP: { org: "Regulatory Application", kor: "주요공시" },
  REA: { org: "Regulatory Approval", kor: "주요공시" },
  RES: { org: "Research Update", kor: "주요공시" },
  RSP: { org: "Response to (insert  appropriate text)", kor: "주요공시" },
  REP: { org: "Restructure Proposals", kor: "구조조정" },
  RAG: { org: "Result of AGM", kor: "주주총회" },
  REG: { org: "Result of EGM", kor: "주주총회" },
  ROI: { org: "Result of Equity Issue", kor: "주요공시" },
  ROM: { org: "Result of Meeting", kor: "주요공시" },
  RTE: { org: "Result of Tender Offer", kor: "주요공시" },
  DCC: {
    org: "Form 8 (DD) - [Insert  name of offeree or  offeror",
    kor: "주요공시"
  },
  RET: {
    org: "Form 8.3 – [Insert name  of offerree or offeror]",
    kor: "주요공시"
  },
  SOA: { org: "Scheme of arrangement", kor: "주요공시" },
  STR: { org: "Statement re (insert  appropriate text)", kor: "주요공시" },
  STC: { org: "Statement re (insert  appropriate text)", kor: "주요공시" },
  OFD: { org: "Statement re Possible  Offer", kor: "주요공시" },
  SPC: { org: "Statement re Press  Comment", kor: "주요공시" },
  SPM: { org: "Statement re Share Price  Movement", kor: "주요공시" },
  SYR: { org: "Syndicate Results", kor: "주요공시" },
  TEN: { org: "Tender Offer", kor: "주요공시" },
  TVR: { org: "Total Voting Rights", kor: "주요공시" },
  TST: { org: "Trading Statement", kor: "주요공시" },
  POS: { org: "Transaction in Own  Shares*", kor: "자사주거래" },
  MSCH: { org: "Miscellaneous", kor: "주요공시" },
  ARI: { org: "Announcement re:  Rights Issue", kor: "일반공시" },
  LIS: { org: "Additional Listing", kor: "일반공시" },
  AIU: { org: "Annual Information  Update", kor: "일반공시" },
  BRC: { org: "Base Rate Change", kor: "일반공시" },
  BLR: { org: "Block listing Interim  Review*", kor: "일반공시" },
  CMC: { org: "Compliance with Model  Code", kor: "일반공시" },
  CAS: { org: "Compulsory Acquisition  of Shares", kor: "일반공시" },
  RDS: { org: "Director/PDMR  Shareholding*", kor: "지분구조변경" },
  BOA: { org: "Directorate change", kor: "주요인사변경" },
  DSP: {
    org: "Disclosure of Short  Position (Rights Issue)*",
    kor: "일반공시"
  },
  DIV: { org: "Dividend Declaration", kor: "일반공시" },
  RC: { org: "FRN Variable Rate Fix", kor: "일반공시" },
  GEO: { org: "Geographical  Distribution", kor: "일반공시" },
  HOL: { org: "Holding(s) in Company*", kor: "일반공시" },
  NAV: { org: "Net Asset Value(s)", kor: "일반공시" },
  PFU: { org: "Portfolio Update", kor: "일반공시" },
  PDI: { org: "Publication of a  prospectus", kor: "일반공시" },
  RTT: { org: "Rule 2.10 Announcement", kor: "일반공시" },
  SSD: { org: "Short Selling Disclosure", kor: "일반공시" },
  TAV: { org: "Total Assets Value", kor: "일반공시" },
  TRS: { org: "Treasury Stock", kor: "자사주거래" },
  MSCM: { org: "Miscellaneous", kor: "일반공시" },
  MSC: { org: "Miscellaneous", kor: "일반공시" },
  CAN: { org: "Change of Name", kor: "기타공시" },
  CIR: {
    org: "Circ re. [insert  appropriate document  title]",
    kor: "기타공시"
  },
  COS: { org: "Company Secretary  Change", kor: "기타공시" },
  RDN: { org: "Director Declaration", kor: "기타공시" },
  DOC: {
    org: "Doc re. [insert  appropriate document  title]",
    kor: "기타공시"
  },
  NAR: { org: "New Accounting Ref  Date", kor: "기타공시" },
  NOA: { org: "Notice of AGM", kor: "기타공시" },
  NOE: { org: "Notice of EGM", kor: "기타공시" },
  NOR: { org: "Notice of Results", kor: "기타공시" },
  ODP: { org: "Offer DocumentPosted", kor: "기타공시" },
  MSCL: { org: "Miscellaneous", kor: "기타공시" },
  NRA: { org: "Non Regulatory (add headline)", kor: "기타공시" },
  DSH: { org: "Director/Share Holder", kor: "내부자 지분변동" },
  NOG: { org: "Notice of General Meeting", kor: "주주총회" },
  APP: { org: "Application for Admission", kor: "주요인사변경" }
};
