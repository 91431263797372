import useMembersAustraliaFilingList from "@/hooks/useMembersAustraliaFilingList";
import useMembersGermanyFilingList from "@/hooks/useMembersGermanyFilingList";
import useMembersHanoiFilingList from "@/hooks/useMembersHanoiFilingList";
import useMembersHochiminhFilingList from "@/hooks/useMembersHochiminhFilingList";
import useMembersHongkongFiling from "@/hooks/useMembersHongKongFiling";
import useMembersJakartaFilingList from "@/hooks/useMembersJakartaFilingList";
import useMembersJapanFiling from "@/hooks/useMembersJapanFiling";
import useMembersLondonFilingList from "@/hooks/useMembersLondonFilingList";
import useMembersShangHaiFiling from "@/hooks/useMembersShangHaiFiling";
import useMembersShenzhenFiling from "@/hooks/useMembersShenzhenFiling";
import useMembersUSAFiling from "@/hooks/useMembersUSAFiling";

import { COUNTRY_CODE } from "@/types/COUNTRY_CODE";
import { EXCHANGE_CODE } from "@/types/EXCHANGE";

const useMembersFiling = (
  countryCode: COUNTRY_CODE,
  exchange: EXCHANGE_CODE,
  secCode: string
) => {
  const isLoading = false;
  const isError = false;

  if (countryCode === COUNTRY_CODE.USA) {
    return useMembersUSAFiling(exchange, secCode);
  }

  if (countryCode === COUNTRY_CODE.HKG) {
    return useMembersHongkongFiling(exchange, secCode);
  }

  if (countryCode === COUNTRY_CODE.CHN) {
    if (exchange === EXCHANGE_CODE.SHC) {
      return useMembersShangHaiFiling(exchange, secCode);
    }

    if (exchange === EXCHANGE_CODE.SHZ) {
      return useMembersShenzhenFiling(exchange, secCode);
    }
  }

  if (countryCode === COUNTRY_CODE.JPN) {
    return useMembersJapanFiling(exchange, secCode);
  }

  if (countryCode === COUNTRY_CODE.VNM) {
    if (exchange === EXCHANGE_CODE.HNX) {
      return useMembersHanoiFilingList(exchange, secCode);
    }
    if (exchange === EXCHANGE_CODE.HSX) {
      return useMembersHochiminhFilingList(exchange, secCode);
    }
  }

  if (countryCode === COUNTRY_CODE.GBR) {
    return useMembersLondonFilingList(exchange, secCode);
  }

  if (countryCode === COUNTRY_CODE.DEU) {
    return useMembersGermanyFilingList(exchange, secCode);
  }

  if (countryCode === COUNTRY_CODE.AUS) {
    return useMembersAustraliaFilingList(exchange, secCode);
  }

  if (countryCode === COUNTRY_CODE.IDN) {
    return useMembersJakartaFilingList(exchange, secCode);
  }

  return { isLoading, isError };
};

export default useMembersFiling;
