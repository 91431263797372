import ErrorPage from "@/components/atoms/list/Error";
import Loader from "@/components/organisms/common/Loading";
import MembersCard from "@/components/organisms/members/MembersCard";
import useMembersFiling from "@/hooks/useMembersFiling";
import { useMembersFilingStore } from "@/stores/filingsStore";
import { COUNTRY_CODE } from "@/types/COUNTRY_CODE";
import { EXCHANGE_CODE } from "@/types/EXCHANGE";
import parseSearchParams from "@/utils/parser/parseSearchParams";
import { useLocation } from "react-router-dom";

const Members = () => {
  const {
    country_code: countryCode,
    exchange,
    sec_code: secCode
  } = parseSearchParams(useLocation().search);

  const { isLoading, isError } = useMembersFiling(
    countryCode as COUNTRY_CODE,
    exchange as EXCHANGE_CODE,
    secCode
  );

  const filing = useMembersFilingStore((state) => state.filing);

  if (isLoading) return <Loader />;
  if (isError) return <ErrorPage />;

  return <MembersCard filing={filing} />;
};

export default Members;
