import { EXCHANGE } from "@/types/EXCHANGE";
import { T_RESPONSE_LONDON_FILINGS } from "@/types/model/response/LondonFilingType";
import { MODEL_VIEW_FILINGS_ITEM } from "@/types/model/view/ParsedFilingType";
import { getKoreanValue } from "@/utils/parser/parseFilingResponse";

type T_PARSE_LONDON_FILING = (
  data: T_RESPONSE_LONDON_FILINGS
) => MODEL_VIEW_FILINGS_ITEM[];

const parseLondonFiling: T_PARSE_LONDON_FILING = (data) => {
  if (!data.getDisclosure) return [];
  return data.getDisclosure.map((item) => {
    return {
      id: item.id,
      dataDate: item.dataDate,
      form: item.details.categoryId
        ? getKoreanValue(item.details.categoryId)
        : "",
      topic: item.analysisDetails.topicKor ?? "",
      summaryTiny: item.analysisDetails.summarizeTinyKor ?? "",
      summaryLong: item.analysisDetails.summarizeLongKor ?? "",
      originURL: item.details.detailUrl ?? "",
      fileType: "html",
      exchange: EXCHANGE.LONDON,
      secCode: item.details.secCode ?? ""
    };
  });
};

export default parseLondonFiling;
