import styled from "@emotion/styled";

import * as Text from "@/components/atoms/common/Text";

export const Summary = styled(Text.Nanum400)`
  font-size: 1.3125rem;
  line-height: 2.25rem;

  color: ${({ theme }) => theme.colors.black70};
`;
