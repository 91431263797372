import styled from "@emotion/styled";

const Circle = styled.div<{ isOn: boolean }>`
  position: absolute;
  width: 1.375rem;
  height: 1.375rem;
  border-radius: 50%;
  left: 0.1875rem;
  background: ${({ theme }) => theme.colors.white};

  transition: all 0.5s ease-in-out;

  ${({ isOn }) => isOn && "transform: translate(1.25rem, 0);"}
`;

export default Circle;
