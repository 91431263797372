import styled from "@emotion/styled";

import HashTag from "@/components/atoms/list/HashTag";
import Tag from "@/components/atoms/list/Tag";

import { MODEL_VIEW_FILINGS_ITEM } from "@/types/model/view/ParsedFilingType";

interface FilingItemHeaderProps {
  item: MODEL_VIEW_FILINGS_ITEM;
}

const FilingItemHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 1rem;
`;

const FilingItemHeader = ({ item }: FilingItemHeaderProps) => {
  const hashTagItems = item?.topic
    ? item.topic
        .split(", ")
        .filter((item) => item.length < 10)
        .slice(0, 3)
    : [];

  return (
    <>
      {(item.form || hashTagItems.length > 0) && (
        <FilingItemHeaderContainer>
          {item.form ? <Tag>{item.form}</Tag> : <></>}
          <HashTag hashTagItems={hashTagItems} />
        </FilingItemHeaderContainer>
      )}
    </>
  );
};

export default FilingItemHeader;
