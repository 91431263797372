export enum EXCHANGE {
  USA = "USA",
  HONGKONG = "HONGKONG",
  SHANGHAI = "SHANGHAI",
  SHENZHEN = "SHENZHEN",
  JAPAN = "JAPAN",
  HANOI = "HANOI",
  HOCHIMINH = "HOCHIMINH",
  LONDON = "LONDON",
  GERMANY = "GERMANY",
  AUSTRALIA = "AUSTRALIA",
  JAKARTA = "JAKARTA"
}

export enum EXCHANGE_CODE {
  // Australia
  ASX = "ASX", // 호주 거래소

  // Germany
  GER = "GER", // 독일

  // UK
  LSE = "LSE", // 런던

  // India
  JKT = "JKT", // 자카르타

  // Vietnam
  HSX = "HSX", // 호치민
  HNX = "HNX", // 하노이

  // China
  SHZ = "SHZ", // 심천
  SHC = "SHC", // 상하이

  // Honkong
  HKG = "HKG", // 홍콩

  // Japan
  TYO = "TYO", // 도쿄

  // USA
  NYY = "NYY",
  BTQ = "BTQ",
  NQQ = "NQQ",
  ASQ = "ASQ",
  PNK = "PNK"
}
