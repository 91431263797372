export interface CustomTheme {
  colors: {
    primary100: string;
    primary70: string;
    primary50: string;
    secondary: string;
    gray100: string;
    gray70: string;
    gray50: string;
    gray30: string;
    blue: string;
    white: string;
    black: string;
    realBlack: string;
    black100: string;
    black70: string;
    black50: string;
    black30: string;
    white100: string;
    white70: string;
    white50: string;
    white30: string;
    white10: string;
  };
}

export const lightTheme: CustomTheme = {
  colors: {
    primary100: "#002C2C",
    primary70: "#4D6B6B",
    primary50: "#84C13D",
    secondary: "#B1F54D",
    gray100: "#DBDBDB",
    gray70: "#C5C5C5",
    gray50: "#F3F3F3",
    gray30: "#BDBDBD",
    blue: "#6FADFF",
    white: "#FFFFFF",
    black: "#333333",
    realBlack: "#000000",
    black100: "#000000",
    black70: "#333333",
    black50: "#666666",
    black30: "#999999",
    white100: "#FFFFFF",
    white70: "#F9F9F9",
    white50: "#F7F7F7",
    white30: "#F0F0F0",
    white10: "#E0E0E0"
  }
};

export const darkTheme: CustomTheme = {
  colors: {
    primary100: "#002C2C",
    primary70: "#4D6B6B",
    primary50: "#84C13D",
    secondary: "#B1F54D",
    gray100: "#DBDBDB",
    gray70: "#C5C5C5",
    gray50: "#F3F3F3",
    gray30: "#BDBDBD",
    blue: "#6FADFF",
    white: "#FFFFFF",
    black: "#333333",
    realBlack: "#000000",
    black100: "#000000",
    black70: "#333333",
    black50: "#666666",
    black30: "#999999",
    white100: "#FFFFFF",
    white70: "#F9F9F9",
    white50: "#F7F7F7",
    white30: "#F0F0F0",
    white10: "#E0E0E0"
  }
};
