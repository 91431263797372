import * as Text from "@/components/atoms/common/Text";
import styled from "@emotion/styled";
import dayjs from "dayjs";
import React from "react";

const MembersFooterContainer = styled.div`
  display: flex;
  align-items: center;

  margin-top: 0.5rem;
  font-size: 1.125rem;
`;

const MembersFooterText = styled(Text.Nanum400)`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.black30};
`;

// const Logo = styled.img`
//   height: 1.125rem;
//   margin-left: 0.2rem;
// `;

const VerticalBar = styled.p`
  color: ${({ theme }) => theme.colors.white10};
  margin: 0 0.625rem;
`;

const MembersFooterDate = styled(Text.Roboto400)`
  display: flex;
  align-items: flex-start;
  color: ${({ theme }) => theme.colors.black30};
`;

interface MembersFooterProps {
  dataDate: string;
}

const MembersFooter: React.FC<MembersFooterProps> = ({ dataDate }) => {
  const time = dayjs(dataDate).tz("Asia/Seoul").format("YYYY.MM.DD HH:mm");

  return (
    <MembersFooterContainer>
      <MembersFooterText>
        by. 크래프트테크놀로지스
        <VerticalBar>|</VerticalBar>
      </MembersFooterText>
      <MembersFooterDate>{time}</MembersFooterDate>
    </MembersFooterContainer>
  );
};

export default MembersFooter;
