import * as Text from "@/components/atoms/common/Text";
import RightArrow from "@/components/atoms/members/RightArrow";
import styled from "@emotion/styled";
import React from "react";

interface MembersHeaderProps {
  formType: string;
}

const MembersHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 1.25rem;
`;

const MembersCardTag = styled.div<{ hasItem: boolean }>`
  display: inline-flex;
  justify-content: center;
  align-items: center;

  padding: 0.4375rem 0.75rem 0.3125rem;
  border-radius: 9999px;

  border: 1px solid #acd580;
  ${({ hasItem }) => (hasItem ? "" : "border-color: transparent;")}

  color: #3c9800;
  font-size: 0.9375rem;
`;

const MembersHeader: React.FC<MembersHeaderProps> = ({ formType }) => {
  return (
    <MembersHeaderContainer>
      <MembersCardTag hasItem={!!formType}>
        <Text.Nanum400>{formType ? formType : <>&nbsp;</>}</Text.Nanum400>
      </MembersCardTag>
      <RightArrow />
    </MembersHeaderContainer>
  );
};

export default MembersHeader;
