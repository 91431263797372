import * as Text from "@/components/atoms/common/Text";
import ErrorPage from "@/components/atoms/list/Error";
import Loader from "@/components/organisms/common/Loading";
import useMembersFiling from "@/hooks/useMembersFiling";
import { useMembersFilingStore } from "@/stores/filingsStore";
import { COUNTRY_CODE } from "@/types/COUNTRY_CODE";
import { EXCHANGE_CODE } from "@/types/EXCHANGE";
import generateLocaleTime from "@/utils/generateLocaleTime";
import parseSearchParams from "@/utils/parser/parseSearchParams";
import styled from "@emotion/styled";
import { useLocation } from "react-router-dom";

const MembersDetailContainer = styled.div`
  padding: 0 1.5rem;
`;

const MembersDetailTitleContainer = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 4.5rem;
`;

const MembersDetailTitle = styled(Text.Nanum600)`
  font-size: 1.3125rem;
  line-height: 2rem;

  color: ${({ theme }) => theme.colors.black};
  word-wrap: break-word;
`;

const MembersDetailDateWrapper = styled.div`
  display: flex;
  margin-top: 1.5rem;
  gap: 0.875rem;
`;

const MembersDetailDate = styled(Text.Nanum400)`
  font-size: 0.9375rem;
  line-height: 1.25rem;

  color: ${({ theme }) => theme.colors.black};
`;

const MembersDetailBodyContainer = styled.div`
  margin-top: 1.5rem;
`;

const MembersDetailBodyText = styled(Text.Nanum400)`
  font-size: 1.3125rem;
  line-height: 2rem;
  word-wrap: break-word;

  white-space: pre-wrap;

  color: ${({ theme }) => theme.colors.black50};
`;

const MembersDetail = () => {
  const {
    country_code: countryCode,
    exchange,
    sec_code: secCode
  } = parseSearchParams(useLocation().search);

  const { isLoading, isError } = useMembersFiling(
    countryCode as COUNTRY_CODE,
    exchange as EXCHANGE_CODE,
    secCode
  );

  const filing = useMembersFilingStore((state) => state.filing);

  const { koreaDay, koreaHour, localDay, localHour } = generateLocaleTime(
    filing[0]?.dataDate,
    filing[0]?.exchange
  );

  if (isLoading) return <Loader />;
  if (isError) return <ErrorPage />;

  return (
    <MembersDetailContainer>
      <MembersDetailTitleContainer>
        <MembersDetailTitle>{filing[0].summaryTiny}</MembersDetailTitle>
        <MembersDetailDateWrapper>
          <MembersDetailDate>{koreaDay}</MembersDetailDate>
          <MembersDetailDate>{koreaHour}</MembersDetailDate>
          <MembersDetailDate>
            ( 현지기준 {localDay} {localHour} )
          </MembersDetailDate>
        </MembersDetailDateWrapper>
      </MembersDetailTitleContainer>
      <MembersDetailBodyContainer>
        <MembersDetailBodyText>{filing[0].summaryLong}</MembersDetailBodyText>
      </MembersDetailBodyContainer>
    </MembersDetailContainer>
  );
};

export default MembersDetail;
