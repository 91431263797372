import styled from "@emotion/styled";
import cloneDeep from "lodash/cloneDeep";
import { useLocation } from "react-router-dom";

import EmptyPage from "@/components/atoms/list/Empty";
import Loader from "@/components/organisms/common/Loading";
import FilingItem from "@/components/organisms/list/FilingItem";

import useFilingList from "@/hooks/useFilingList";
import { useFilingsStore } from "@/stores/filingsStore";
import parseSearchParams from "@/utils/parser/parseSearchParams";

import ErrorPage from "@/components/atoms/list/Error";
import ToggleArea from "@/components/organisms/list/ToggleArea";
import useScrollStore from "@/stores/scrollStore";
import useToggleStore from "@/stores/toggleStore";
import { COUNTRY_CODE } from "@/types/COUNTRY_CODE";
import { EXCHANGE_CODE } from "@/types/EXCHANGE";
import { logEvent } from "@/utils/logEvent";
import { MODEL_VIEW_FILINGS } from "@/utils/parser/parseFilingResponse";
import { useEffect, useState } from "react";

const MainContainer = styled.main<{ countryCode: string }>`
  display: flex;
  flex-direction: column;

  margin-top: ${({ countryCode }) =>
    countryCode === COUNTRY_CODE.USA ? "0.9rem" : "1.9rem"};
  margin-bottom: 4.5rem;

  gap: 0.75rem;
`;

const FilingItemWrapper = styled.div`
  padding: 0 1.5rem;
`;

const Main = () => {
  const [filteredFilings, setFilteredFilings] = useState<MODEL_VIEW_FILINGS>(
    []
  );

  const {
    country_code: countryCode,
    exchange,
    sec_code: secCode
  } = parseSearchParams(useLocation().search);

  const { isLoading, isError } = useFilingList(
    countryCode as COUNTRY_CODE,
    exchange as EXCHANGE_CODE,
    secCode
  );

  const filings = useFilingsStore((state) => state.filings);
  const isToggled = useToggleStore((state) => state.isToggled);
  const scroll = useScrollStore((state) => state.scroll);

  useEffect(() => {
    if (filings.length !== 0) {
      const originData = cloneDeep(filings);
      if (countryCode === COUNTRY_CODE.USA && !isToggled) {
        const filtered = originData.filter(
          (filingItem) =>
            filingItem.form !== "내부자 지분취득" &&
            filingItem.form !== "내부자 지분변동" &&
            filingItem.form !== "내부자 지분변동(연)"
        );
        setFilteredFilings(filtered);
      } else {
        setFilteredFilings(originData);
      }
    }
  }, [isToggled, filings]);

  useEffect(() => {
    const timer = setTimeout(() => {
      window.scrollTo(0, scroll);
    }, 5);
    return () => clearTimeout(timer);
  }, [scroll]);

  useEffect(() => {
    logEvent("enter list page", {
      countryCode,
      exchange,
      secCode
    });
  }, []);

  if (isLoading) return <Loader />;
  if (isError) return <ErrorPage />;

  return (
    <MainContainer countryCode={countryCode}>
      {filteredFilings.length === 0 ? (
        <EmptyPage />
      ) : (
        <>
          {countryCode === COUNTRY_CODE.USA && <ToggleArea />}
          <FilingItemWrapper>
            {filteredFilings.map(
              (filingItem, idx) =>
                filingItem.summaryTiny && (
                  <FilingItem
                    key={`${filingItem.id}_${idx}`}
                    item={filingItem}
                  />
                )
            )}
          </FilingItemWrapper>
        </>
      )}
    </MainContainer>
  );
};

export default Main;
