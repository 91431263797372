import { EXCHANGE } from "@/types/EXCHANGE";
import { T_RESPONSE_AUSTRALIA_FILINGS } from "@/types/model/response/AustraliaFilingType";
import { MODEL_VIEW_FILINGS_ITEM } from "@/types/model/view/ParsedFilingType";

type T_PARSE_AUSTRALIA_FILING = (
  data: T_RESPONSE_AUSTRALIA_FILINGS
) => MODEL_VIEW_FILINGS_ITEM[];

// const extensionParseRegex = /\?display=(.+)&/;
const domain = "https://www.asx.com.au/";

const parseAustraliaFiling: T_PARSE_AUSTRALIA_FILING = (data) => {
  if (!data.getDisclosure) return [];
  return data.getDisclosure.map((item) => {
    return {
      id: item.id,
      dataDate: item.dataDate,
      form: "",
      topic: item.analysisDetails.topicKor ?? "",
      summaryTiny: item.analysisDetails.summarizeTinyKor ?? "",
      summaryLong: item.analysisDetails.summarizeLongKor ?? "",
      originURL: domain + (item.details.detailUrl ?? ""),
      fileType: "html",
      // fileType:
      //   (item.details.detailUrl ?? "").match(extensionParseRegex)?.[1] ?? "",
      exchange: EXCHANGE.AUSTRALIA,
      secCode: item.details.secCode ?? ""
    };
  });
};

export default parseAustraliaFiling;
