import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { gql } from "graphql-request";

import { useBaseDate, useFilingsStore } from "@/stores/filingsStore";

import { T_RESPONSE_HONGKONG_FILINGS } from "@/types/model/response/HongkongFilingType";
import createGraphqlClient from "@/utils/createGraphqlClient";
import parseHongkongFiling from "@/utils/parser/parseHongkongFiling";

const query = (baseDate: string, secCode?: string) => gql`
  query HONGKONG {
    getDisclosure(
      filter: {
        exchangeType: HONGKONG
        ${secCode ? `securityCode: "${secCode}"` : ""}
        limit: 100
        page: 1
        timeRange: { endDate: "${baseDate}", rangeLength: 1, rangeLengthUnit: Y }
      }
    ) {
      id
      dataDate
      details {
        ... on Hongkong {
          secCode
          categoryId
          fileLink
          fileType
        }
      }
      analysisDetails {
        topicKor
        summarizeTinyKor
        summarizeLongKor
      }
    }
  }
`;

const useHongKongFilingList = (exchange: string, secCode?: string) => {
  const baseDate = useBaseDate((state) =>
    dayjs(state.baseDate).format("YYYY-MM-DD")
  );

  const setFilings = useFilingsStore((state) => state.setFilings);

  const client = createGraphqlClient();

  const { isLoading, isError } = useQuery(
    [exchange, secCode, baseDate],
    () => client.request<T_RESPONSE_HONGKONG_FILINGS>(query(baseDate, secCode)),
    {
      enabled: true,
      refetchOnWindowFocus: false,
      select: (data) => {
        return parseHongkongFiling(data);
      },
      networkMode: "always",
      staleTime: 5 * 60 * 1000,
      onSuccess(data) {
        setFilings(data);
      }
    }
  );

  return { isLoading, isError };
};

export default useHongKongFilingList;
