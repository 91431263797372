import * as Text from "@/components/atoms/common/Text";
import parseSearchParams from "@/utils/parser/parseSearchParams";
import styled from "@emotion/styled";
import { useLocation } from "react-router-dom";

const MembersBodyContainer = styled.span`
  font-size: 1.3125rem;
  line-height: 2rem;
  display: table-cell;
  vertical-align: middle;
`;

const MembersBodyText = styled(Text.Nanum400)`
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;

  &:hover {
    cursor: pointer;
  }
`;

interface MembersBodyProps {
  summaryLong: string;
}

const MembersBody = ({ summaryLong }: MembersBodyProps) => {
  const getDetailEvent = () => {
    const {
      country_code: countryCode,
      exchange,
      sec_code: secCode
    } = parseSearchParams(useLocation().search);

    const detailURI = `https://nh.disclosure.qraft.ai/members/detail?country_code=${countryCode}&exchange=${exchange}&sec_code=${secCode}`;

    return `hybrid://SendDataToForm/{"FunctionName":"OnReceiveData","Data":"OpenDetailPage^${encodeURIComponent(
      detailURI
    )}"}`;
  };

  return (
    <MembersBodyContainer>
      <a href={getDetailEvent()}>
        <MembersBodyText>{summaryLong}</MembersBodyText>
      </a>
    </MembersBodyContainer>
  );
};

export default MembersBody;
