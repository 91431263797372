import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { gql } from "graphql-request";

import { useBaseDate, useMembersFilingStore } from "@/stores/filingsStore";

import { EXCHANGE_CODE } from "@/types/EXCHANGE";
import { T_RESPONSE_LONDON_FILINGS } from "@/types/model/response/LondonFilingType";
import createGraphqlClient from "@/utils/createGraphqlClient";
import parseLondonFiling from "@/utils/parser/parseLondonFiling";

const query = (baseDate: string, secCode?: string | number) => gql`
  query London {
    getDisclosure(
      filter: {
        exchangeType: LONDON
        ${secCode ? `securityCode: "${secCode}"` : ""}
        limit: 1
        page: 1
        timeRange: { endDate: "${baseDate}", rangeLength: 1, rangeLengthUnit: Y }
      }
    ) {
      id
      dataDate
      details {
        ... on London {
          categoryId
          secCode
          detailUrl
        }
      }
      analysisDetails {
        topicKor
        summarizeTinyKor
        summarizeLongKor
      }
    }
  }
`;

const useMembersLondonFilingList = (
  exchange: EXCHANGE_CODE,
  secCode?: string
) => {
  const baseDate = useBaseDate((state) =>
    dayjs(state.baseDate).format("YYYY-MM-DD")
  );

  const setFilings = useMembersFilingStore((state) => state.setFiling);

  const client = createGraphqlClient();

  const { isLoading, isError } = useQuery(
    [exchange, secCode, baseDate, "members"],
    () => client.request<T_RESPONSE_LONDON_FILINGS>(query(baseDate, secCode)),
    {
      enabled: true,
      refetchOnWindowFocus: false,
      select: (data) => {
        return parseLondonFiling(data);
      },
      networkMode: "always",
      staleTime: 5 * 60 * 1000,
      onSuccess(data) {
        setFilings(data);
      }
    }
  );

  return { isLoading, isError };
};

export default useMembersLondonFilingList;
