import { getLocaleTime, getTime } from "@/utils/getTime";

import { EXCHANGE } from "@/types/EXCHANGE";

type GenerateLocaleTime = (
  time: string,
  exchange?: EXCHANGE
) => {
  koreaDay: string;
  koreaHour: string;
  localDay: string;
  localHour: string;
};

const generateLocaleTime: GenerateLocaleTime = (time, exchange) => {
  const korea = getTime(time, "Asia/Seoul");
  const local = exchange
    ? getLocaleTime(time, exchange)
    : { day: "", hour: "" };

  return {
    koreaDay: korea.day,
    koreaHour: korea.hour,
    localDay: local.day,
    localHour: local.hour
  };
};

export default generateLocaleTime;
