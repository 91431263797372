import styled from "@emotion/styled";

import { TagText } from "@/components/atoms/list/TagText";

interface TagProps {
  children: string;
}

const TagContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.9375rem 0.875rem 0.8125rem;
  height: 2rem;

  border: 1px solid ${({ theme }) => theme.colors.black100};
  border-radius: 1rem;

  background-color: ${({ theme }) => theme.colors.white100};
`;

const Tag = ({ children }: TagProps) => {
  return (
    <TagContainer>
      <TagText>{children}</TagText>
    </TagContainer>
  );
};

export default Tag;
