import { create } from "zustand";

import { MODEL_VIEW_FILINGS } from "@/utils/parser/parseFilingResponse";

interface T_BASE_DATE_STORE {
  baseDate: Date | null;
  setBaseDate: (date: Date | null) => void;
}

export const useBaseDate = create<T_BASE_DATE_STORE>((set) => ({
  baseDate: new Date(),
  setBaseDate: (date) => set({ baseDate: date })
}));

interface T_FILINGS_STORE {
  filings: MODEL_VIEW_FILINGS;
  setFilings: (filings?: MODEL_VIEW_FILINGS) => void;
}

export const useFilingsStore = create<T_FILINGS_STORE>((set) => ({
  filings: [],
  setFilings: (filings) => set({ filings: filings ? filings : [] })
}));

interface T_FILING_STORE {
  filing: MODEL_VIEW_FILINGS;
  setFiling: (filing?: MODEL_VIEW_FILINGS) => void;
}

export const useMembersFilingStore = create<T_FILING_STORE>((set) => ({
  filing: [],
  setFiling: (filing) => set({ filing: filing ? filing : [] })
}));
