import { EXCHANGE } from "@/types/EXCHANGE";
import { T_RESPONSE_JAPAN_FILINGS } from "@/types/model/response/JapanFilingType";
import { MODEL_VIEW_FILINGS_ITEM } from "@/types/model/view/ParsedFilingType";
import { getKoreanValue } from "@/utils/parser/parseFilingResponse";

type T_PARSE_JAPAN_FILING = (
  data: T_RESPONSE_JAPAN_FILINGS
) => MODEL_VIEW_FILINGS_ITEM[];

const parseJapanFiling: T_PARSE_JAPAN_FILING = (data) => {
  if (!data.getDisclosure) return [];
  return data.getDisclosure.map((item) => {
    return {
      id: item.id,
      dataDate: item.dataDate,
      form: getKoreanValue(item.details.categoryId),
      topic: item.analysisDetails.topicKor,
      summaryTiny: item.analysisDetails.summarizeTinyKor,
      summaryLong: item.analysisDetails.summarizeLongKor,
      originURL: item.details.fileLink,
      fileType: "ASPX",
      exchange: EXCHANGE.JAPAN,
      secCode: item.details.secCode[0] || item.details.secCode[1] || ""
    };
  });
};

export default parseJapanFiling;
