import styled from "@emotion/styled";

const ToggleBack = styled.div<{ isOn: boolean }>`
  position: absolute;
  width: 3rem;
  height: 1.75rem;
  border-radius: 1rem;
  background: ${({ theme, isOn }) =>
    isOn ? theme.colors.black : theme.colors.gray70};
`;

export default ToggleBack;
