import { EXCHANGE } from "@/types/EXCHANGE";
import { T_RESPONSE_JAKARTA_FILINGS } from "@/types/model/response/JakartaFilingType";
import { MODEL_VIEW_FILINGS_ITEM } from "@/types/model/view/ParsedFilingType";

type T_PARSE_JAKARTA_FILING = (
  data: T_RESPONSE_JAKARTA_FILINGS
) => MODEL_VIEW_FILINGS_ITEM[];

const parseJakartaFiling: T_PARSE_JAKARTA_FILING = (data) => {
  if (!data.getDisclosure) return [];
  return data.getDisclosure.map((item) => {
    return {
      id: item.id,
      dataDate: item.dataDate,
      form: "",
      topic: item.analysisDetails.topicKor ?? "",
      summaryTiny: item.analysisDetails.summarizeTinyKor ?? "",
      summaryLong: item.analysisDetails.summarizeLongKor ?? "",
      originURL: item.details.realFilePath ?? "",
      fileType: item.details.fileType ?? "" ?? "",
      exchange: EXCHANGE.JAKARTA,
      secCode: item.details.secCode ?? ""
    };
  });
};

export default parseJakartaFiling;
