import { EXCHANGE } from "@/types/EXCHANGE";
import { T_RESPONSE_HOCHIMINH_FILINGS } from "@/types/model/response/HochiminhFilingType";
import { MODEL_VIEW_FILINGS_ITEM } from "@/types/model/view/ParsedFilingType";

type T_PARSE_HOCHIMINH_FILING = (
  data: T_RESPONSE_HOCHIMINH_FILINGS
) => MODEL_VIEW_FILINGS_ITEM[];

const parseHochiminhFiling: T_PARSE_HOCHIMINH_FILING = (data) => {
  if (!data.getDisclosure) return [];
  return data.getDisclosure.map((item) => {
    const originURL = item.details.fileLink?.[0] ?? "";
    return {
      id: item.id,
      dataDate: item.dataDate,
      form: "",
      topic: item.analysisDetails.topicKor,
      summaryTiny: item.analysisDetails.summarizeTinyKor,
      summaryLong: item.analysisDetails.summarizeLongKor,
      originURL: originURL,
      fileType: originURL ? "PDF" : "",
      exchange: EXCHANGE.HOCHIMINH,
      secCode: item.details.secCode
    };
  });
};

export default parseHochiminhFiling;
