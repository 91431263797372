export enum COUNTRY_CODE {
  CHN = "CHN", // China (중국)
  HKG = "HKG", // Hongkong (홍콩)
  JPN = "JPN", // Japan (일본)
  USA = "USA", // USA (미국)
  VNM = "VNM", // Vietnam (베트남)
  IDN = "IDN", // India (인도네시아)
  GBR = "GBR", // Great Britain (영국)
  DEU = "DEU", // Deutschland (독일)
  AUS = "AUS" // Australia (호주)
}
