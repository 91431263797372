import styled from "@emotion/styled";

const RightArrowWrapper = styled.a`
  width: 1.5rem;
  height: 1.5rem;
`;

const RightArrow = () => {
  return (
    <RightArrowWrapper href='hybrid://SendDataToForm/{"FunctionName":"OnReceiveData","Data":"OpenMoreIssues"}'>
      <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.25243 3.29289C8.64295 2.90237 9.27611 2.90237 9.66664 3.29289L18.3737 12L9.66664 20.7071C9.27612 21.0976 8.64295 21.0976 8.25243 20.7071C7.8619 20.3166 7.8619 19.6834 8.25243 19.2929L15.5453 12L8.25243 4.70711C7.8619 4.31658 7.8619 3.68342 8.25243 3.29289Z"
          fill="#C5C5C5"
        />
      </svg>
    </RightArrowWrapper>
  );
};

export default RightArrow;
