import { ThemeProvider } from "@emotion/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { useState } from "react";

import { darkTheme, lightTheme } from "@/styles/theme";
import * as Sentry from "@sentry/react";

import Router from "@/router/router";

const queryClient = new QueryClient();

const App = () => {
  // 다크모드를 확인하는 API를 호출해서 받아온다고 가정
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isDark, setIsDark] = useState<boolean>(false);

  return (
    <Sentry.ErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={isDark ? darkTheme : lightTheme}>
          <Router />
        </ThemeProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </Sentry.ErrorBoundary>
  );
};

export default App;
