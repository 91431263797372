import styled from "@emotion/styled";

import * as Text from "@/components/atoms/common/Text";

export const TagText = styled(Text.Nanum600)`
  font-size: 0.9375rem;
  line-height: 1.25rem;

  color: ${({ theme }) => theme.colors.black70};
`;
