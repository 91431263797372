import useAustraliaFilingList from "@/hooks/useAustraliaFilingList";
import useGermanyFilingList from "@/hooks/useGermanyFilingList";
import useHanoiFilingList from "@/hooks/useHanoiFilingList";
import useHochiminhFilingList from "@/hooks/useHochiminhFilingList";
import useHongKongFilingList from "@/hooks/useHongKongFilingList";
import useJakartaFilingList from "@/hooks/useJakartaFilingList";
import useJapanFilingList from "@/hooks/useJapanFilingList";
import useLondonFilingList from "@/hooks/useLondonFilingList";
import useShangHaiFilingList from "@/hooks/useShangHaiFilingList";
import useShenZhenFilingList from "@/hooks/useShenZhenFilingList";
import useUSAFilingList from "@/hooks/useUSAFilingList";

import { COUNTRY_CODE } from "@/types/COUNTRY_CODE";
import { EXCHANGE_CODE } from "@/types/EXCHANGE";

const useFilingList = (
  countryCode: COUNTRY_CODE,
  exchange: EXCHANGE_CODE,
  secCode: string
) => {
  const isLoading = false;
  const isError = false;

  if (countryCode === COUNTRY_CODE.USA) {
    return useUSAFilingList(exchange, secCode);
  }

  if (countryCode === COUNTRY_CODE.HKG) {
    return useHongKongFilingList(exchange, secCode);
  }

  if (countryCode === COUNTRY_CODE.CHN) {
    if (exchange === EXCHANGE_CODE.SHC) {
      return useShangHaiFilingList(exchange, secCode);
    }

    if (exchange === EXCHANGE_CODE.SHZ) {
      return useShenZhenFilingList(exchange, secCode);
    }
  }

  if (countryCode === COUNTRY_CODE.JPN) {
    return useJapanFilingList(exchange, secCode);
  }

  if (countryCode === COUNTRY_CODE.VNM) {
    if (exchange === EXCHANGE_CODE.HNX) {
      return useHanoiFilingList(exchange, secCode);
    }
    if (exchange === EXCHANGE_CODE.HSX) {
      return useHochiminhFilingList(exchange, secCode);
    }
  }

  if (countryCode === COUNTRY_CODE.GBR) {
    return useLondonFilingList(exchange, secCode);
  }

  if (countryCode === COUNTRY_CODE.DEU) {
    return useGermanyFilingList(exchange, secCode);
  }

  if (countryCode === COUNTRY_CODE.AUS) {
    return useAustraliaFilingList(exchange, secCode);
  }

  if (countryCode === COUNTRY_CODE.IDN) {
    return useJakartaFilingList(exchange, secCode);
  }

  return { isLoading, isError };
};

export default useFilingList;
