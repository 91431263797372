import { Link, useLocation } from "react-router-dom";

import { Summary } from "@/components/atoms/list/Summary";

import useScrollStore from "@/stores/scrollStore";
import { MODEL_VIEW_FILINGS_ITEM } from "@/types/model/view/ParsedFilingType";
import { logEvent } from "@/utils/logEvent";
import parseSearchParams from "@/utils/parser/parseSearchParams";

interface FilingItemBodyProps {
  item: MODEL_VIEW_FILINGS_ITEM;
}

const FilingItemBody = ({ item }: FilingItemBodyProps) => {
  const {
    country_code: countryCode,
    exchange,
    sec_code: secCode
  } = parseSearchParams(useLocation().search);

  const encodeDetailURI = `/detail?id=${encodeURIComponent(
    item.id
  )}&country_code=${countryCode}&exchange=${exchange}&sec_code=${secCode}`;

  const setScroll = useScrollStore((state) => state.setScroll);

  const handleOnClick = () => {
    setScroll(window.scrollY);
    logEvent("enter detail page", {
      id: item.id,
      countryCode,
      exchange,
      secCode,
      scrollY: window.scrollY
    });
  };

  return (
    <Link to={encodeDetailURI} onClick={handleOnClick}>
      <Summary>{item.summaryTiny}</Summary>
    </Link>
  );
};

export default FilingItemBody;
