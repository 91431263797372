import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { gql } from "graphql-request";

import { useBaseDate, useMembersFilingStore } from "@/stores/filingsStore";

import { T_RESPONSE_SHENZHEN_FILINGS } from "@/types/model/response/ShenzhenFilingType";
import createGraphqlClient from "@/utils/createGraphqlClient";
import parseShenzhenFiling from "@/utils/parser/parseShenzhenFiling";

const query = (baseDate: string, secCode?: string) => gql`
  query SHENZHEN {
    getDisclosure(
      filter: {
        exchangeType: SHENZHEN
        ${secCode ? `securityCode: "${secCode}"` : ""}
        limit: 1
        page: 1
        timeRange: { endDate: "${baseDate}", rangeLength: 1, rangeLengthUnit: Y }
      }
    ) {
      id
      dataDate
      details {
        ... on Shenzhen {
          secCode
          categoryId
          fileLink
          fileType
        }
      }
      analysisDetails {
        topicKor
        summarizeTinyKor
        summarizeLongKor
      }
    }
  }
`;

const useMembersShenzhenFiling = (exchange: string, secCode?: string) => {
  const baseDate = useBaseDate((state) =>
    dayjs(state.baseDate).format("YYYY-MM-DD")
  );

  const setFiling = useMembersFilingStore((state) => state.setFiling);

  const client = createGraphqlClient();

  const { isLoading, isError } = useQuery(
    [exchange, secCode, baseDate, "members"],
    () => client.request<T_RESPONSE_SHENZHEN_FILINGS>(query(baseDate, secCode)),
    {
      enabled: true,
      refetchOnWindowFocus: false,
      select: (data) => {
        return parseShenzhenFiling(data);
      },
      networkMode: "always",
      staleTime: 5 * 60 * 1000,
      onSuccess(data) {
        setFiling(data);
      }
    }
  );

  return { isLoading, isError };
};

export default useMembersShenzhenFiling;
