import styled from "@emotion/styled";

const Nanum400 = styled.span`
  font-family: "NanumBarunGothic";
  font-weight: 400;
`;

const Nanum600 = styled.span`
  font-family: "NanumBarunGothicBold";
  font-weight: 600;
`;

const Roboto400 = styled.span`
  font-family: "Roboto";
  font-weight: 400;
`;
const Roboto500 = styled.span`
  font-family: "Roboto";
  font-weight: 500;
`;

export { Nanum400, Nanum600, Roboto400, Roboto500 };
