import styled from "@emotion/styled";

import * as Text from "@/components/atoms/common/Text";

interface HashTagProps {
  hashTagItems: string[];
}

const HashTagContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.125rem;
  flex: 1;
`;

const HashTagText = styled(Text.Nanum400)<{ itemLength: number }>`
  font-size: 1.125rem;

  text-overflow: ellipsis;
  overflow: hidden;

  display: -webkit-box;
  word-break: break-all;
  word-wrap: break-word;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  color: ${({ theme }) => theme.colors.black50};
`;

const HASH_ITEM_LENGTH = 2;

const HashTag = ({ hashTagItems }: HashTagProps) => {
  return (
    <HashTagContainer>
      {hashTagItems?.map(
        (item, idx) =>
          item && (
            <HashTagText key={idx} itemLength={HASH_ITEM_LENGTH}>
              #{item}
            </HashTagText>
          )
      )}
    </HashTagContainer>
  );
};

export default HashTag;
