import styled from "@emotion/styled";

import * as Text from "@/components/atoms/common/Text";

interface TimeStampProps {
  time: string;
}

const TimeStampText = styled(Text.Roboto400)`
  font-size: 0.95rem;
  line-height: 1.375rem;

  color: ${({ theme }) => theme.colors.black30};
`;

const TimeStamp = ({ time }: TimeStampProps) => {
  return <TimeStampText>{time}</TimeStampText>;
};

export default TimeStamp;
